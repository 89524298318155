import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navi from './components/common/navi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './components/common/footer';
import Home from './components/homepage/home';
import PopularNumbersPage from './components/pages/popularNumberPage';
import PopularGoldenNumbersPage from './components/pages/popularGoldenNumberPage';
import RecentJackpotListPage from './components/pages/recentJackpotWinnerPage';
import CollectNumberPage from './components/pages/collectNumberPage';


function App() {
  return (
    <Container>
      <Row>
        <Col xl={12} xs={12} md={12} sm={12}>
          <br />
          <Navi />
        </Col>
      </Row>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/popularnumbers" element={<PopularNumbersPage />} />
          <Route path="/populargoldennumbers" element={<PopularGoldenNumbersPage />} />
          <Route path="/recentjackpotwinner" element={<RecentJackpotListPage />} />
          <Route path="/collectnumbers" element={<CollectNumberPage />} />
        </Routes>
      </BrowserRouter>
      {/* <Home /> */}
      <br />
      <Footer />
    </Container>
  );
}

export default App;
