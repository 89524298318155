import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Api from '../../externals/api';

function PopularJackpotwithDates(props: any) {
    const [ popularWithDates, setPopularWithDates] = useState<any>([]);
    const [expandedKeys, setExpandedKeys] = useState(null);

    const callPopular = async (options: any) => {
        const result = await Api.popularJackpotWithDates(options);
        if (result.data.data) {
            let results: any[] = result.data.data;
            setPopularWithDates(results);
        }
    }

    useEffect(() => {
        callPopular(props.options);
    }, []);

    return (
        <>
            <h2 className='width100-background-orange'>Popular {`${props.options.name}`} Numbers With Dates</h2>
            <div className="card">
                <DataTable value={popularWithDates} className="sm-4" tableStyle={{ minWidth: '10rem' }}>
                    <Column field="number" header="Number"></Column>
                    <Column field="count" header="Count"></Column>
                </DataTable>
                <span className="research-more-box"><a href="" className="bold-a-href">Research More ...</a></span>
            </div>
        </>
    )
}

export default PopularJackpotwithDates;