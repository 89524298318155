import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Moment from 'moment';
import Api from '../../externals/api';
import Numbers from '../support/numbers';

function JackpotList(props: any) {
    const [ jackportList, setJackportList] = useState<any>([]);

    const callPopular = async (options: any) => {
        const result = await Api.jackpotList(options);
        if (result.data.data) {
            sortNumbers(result.data.data);
        }
    }

    const moveArray = (data: any, find: any) => {
        var index = data.findIndex(function(item: any) {
            return item.type === find
        });
        if (index === 6) return data;
        var f = data.splice(index, 1)[0];
        data.splice(6, 0, f);
        return data;
    }

    const sortNumbers = async (jackportList: any[]) => {
        let results: any[] = jackportList.map((item: any, i: number) => {
            let resultsDate: any[] = (item.numbers).sort((a: any) => {
                if (a.type === 'number') {
                    return -1;
                } else if (a.type === 'multiplier') {
                     return 1;
                } else { return 0; }
            });
            item.numbers = moveArray(resultsDate, 'multiplier');
            return item;;
        }) as any[];
        setJackportList(results);
    }

    useEffect(() => {
        callPopular(props.options);
    }, []);

    return (
        <>
            <h2 className='width100-background-purple'>Recent {`${props.options.name}`} Winner</h2>
            <div className="card padding10">
                { jackportList.length > 0 && jackportList.map((item: any, i: number) => {
                    return (
                        <p key={i}>
                            <Numbers numbers={item.numbers} />
                            <p className="list-number-date">Date: {Moment(item.date).format('DD/MM/YYYY')}</p>
                        </p>
                    )
                })}
                <span className="research-more-box"><a href="" className="bold-a-href">Research More ...</a></span>
            </div>
        </>
    )
}

export default JackpotList;