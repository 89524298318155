import React, { useState, useEffect } from 'react';

function Footer() {

    useEffect(() => {}, []);

    return (
        <div className='footer'>
            <p>Copyright All Rights Reserved © 2023 | Terms & Conditions</p>
        </div>
    )
}

export default Footer;