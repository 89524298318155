import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PopularNumbers from './popularNumber';
import PopularGolden from './pupularGolden';
import PopularJackpotWithDates from './pupularNumberWithDates';
import MostRecentNumber from './mostRecentNumber';
import JackpotList from './jackpotList';

function Home() {

    const optionsPowerball = {
        "name": "Powerball",
        "limit": 5
    } as object;

    const optionsMegaMillion = {
        "name": "Mega Millions",
        "limit": 5
    } as object;

    const optionMegaMillionsGolden = {
        "name": "Mega Millions",
        "limit": 5,
        "skip": 0
    } as object;

    const optionPopularMegaMillionsWithDates = {
        "name": "Mega Millions",
        "limit": 5,
        "skip": 0
    } as object;

    const optionPowerballGolden = {
        "name": "Powerball",
        "limit": 5,
        "skip": 0
    } as object;

    const optionPopularPowerballWithDates = {
        "name": "Powerball",
        "limit": 5,
        "skip": 0
    } as object;

    const recentPowerBallNumber = {
        "name": "Powerball",
        "limit": 5
    } as object;

    const recentMegaMillionsNumber = {
        "name": "Mega Millions",
        "limit": 5
    } as object;

    const powerBallList = {
        "name": "Powerball",
        "limit": 5
    } as object;

    const megaMillionsList = {
        "name": "Mega Millions",
        "limit": 5
    } as object;

    return (
        <Row>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularNumbers options={optionsPowerball} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularNumbers options={optionsMegaMillion} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularGolden options={optionPowerballGolden} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularGolden options={optionMegaMillionsGolden} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularJackpotWithDates options={optionPopularPowerballWithDates} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <PopularJackpotWithDates options={optionPopularMegaMillionsWithDates} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <MostRecentNumber options={recentPowerBallNumber} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <MostRecentNumber options={recentMegaMillionsNumber} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <JackpotList options={powerBallList} />
            </Col>
            <Col xl={3} md={4} sm={6} xs={12}>
                <JackpotList options={megaMillionsList} />
            </Col>
        </Row>
    )
}

export default Home;