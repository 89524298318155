import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ListBox } from 'primereact/listbox';
import { Panel } from 'primereact/panel';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button'
import Moment from 'moment';
import Api from '../../externals/api';
import Numbers from '../support/numbers';
import AddNumber from '../support/addNumber';

function JackpotListPage(props: any) {
    const [ jackportList, setJackportList] = useState<any>([]);
    const [limit, setLimit] = useState<number>(5);
    const [selectedJackpot, setSelectedJackpot] = useState({ name: 'Powerball' });

    const callPopular = async (options: any) => {
        const result = await Api.jackpotList(options);
        if (result.data.data) {
            sortNumbers(result.data.data);
        }
    }

    const jackpots = [
        { name: 'Powerball' },
        { name: 'Mega Millions' }
    ];

    const moveArray = (data: any, find: any) => {
        var index = data.findIndex(function(item: any) {
            return item.type === find
        });
        if (index === 6) return data;
        var f = data.splice(index, 1)[0];
        data.splice(6, 0, f);
        return data;
    }

    const sortNumbers = async (jackportList: any[]) => {
        let results: any[] = jackportList.map((item: any, i: number) => {
            let resultsDate: any[] = (item.numbers).sort((a: any) => {
                if (a.type === 'number') {
                    return -1;
                } else if (a.type === 'multiplier') {
                     return 1;
                } else { return 0; }
            });
            item.numbers = moveArray(resultsDate, 'multiplier');
            return item;;
        }) as any[];
        setJackportList(results);
    }

    const jackpotList = {
        "name": selectedJackpot.name,
        "limit": limit ? limit : 5
    } as any;

    useEffect(() => {
        callPopular(jackpotList);
    }, [selectedJackpot, limit]);

    return (
        <>
            <Col xl={12} md={12} sm={12} xs={12}>
                <br />
                <Panel header="Search Jackpot">
                    <form onSubmit={(e)=>{ callPopular(jackpotList); e.preventDefault(); }} className="inline-block width100">
                        <Row xl={12} md={12} sm={12} xs={12}>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin10">
                                <label htmlFor="integeronly" className="font-bold block mb-2">Select Jackpot</label>
                                <Dropdown optionLabel="name" value={selectedJackpot} options={jackpots} onChange={(e) => { setSelectedJackpot(e.value)  }} placeholder="Select a Jackpot"/>
                            </Col>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin10">
                                <label htmlFor="integeronly" className="font-bold block mb-2">No. of Data</label>
                                <InputNumber inputId="integeronly" value={limit} onChange={(e) => { setLimit(e.value as number) }} />
                            </Col>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin20">
                                <Button label="Search" icon="pi pi-search" className="button-search" />
                            </Col>
                        </Row>
                    </form>
                </Panel>
                <h2 className='width100-background-purple'>Recent {`${jackpotList.name}`} Winner ({jackportList.length})</h2>
                <div className="card padding10">
                    { jackportList.length > 0 && jackportList.map((item: any, i: number) => {
                        return (
                            <p key={i}>
                                <Numbers numbers={item.numbers} />
                                <p className="list-number-date">Date: {Moment(item.date).format('DD/MM/YYYY')}</p>
                            </p>
                        )
                    })}
                </div>
            </Col>
        </>
    )
}

export default JackpotListPage;