import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ListBox } from 'primereact/listbox';
import { Panel } from 'primereact/panel';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button'
import Api from '../../externals/api';
import { CookieFunctions } from '../../internals/functions';

function PopularGoldenNumbersPage() {
    const [ popularNumber, setPopularNumber] = useState<any>([]);
    const [limit, setLimit] = useState<number>(5);
    const [skip, setSkip] = useState<number>(0);
    const [selectedJackpot, setSelectedJackpot] = useState({ name: 'Powerball' });

    const jackpots = [
        { name: 'Powerball' },
        { name: 'Mega Millions' }
    ];

    const optionPopularWithDates = {
        "name": selectedJackpot.name as string,
        "limit": limit ? limit : 5,
        "skip": skip ? skip : 0
    } as any;

    const callPopular = async (options: any) => {
        const result = await Api.popularGolden(options);
        console.log(result);
        if (result.data.data) {
            setPopularNumber(result.data.data);
        }
    }

    const addNumber = async (number: string, type: string, value: string) => {
        await CookieFunctions.set(selectedJackpot.name, type, value, 3600);
        await CookieFunctions.get();
    }

    const addNumberTemplate = (thenumber: any) => {
        return (<span onClick={() => { addNumber(thenumber.number, 'goldens', thenumber.golden) }}>{thenumber.golden}</span>);
    }

    useEffect(() => {
        callPopular(optionPopularWithDates);
    }, [selectedJackpot, limit, skip]);

    return (
        <>
            <Col xl={12} md={12} sm={12} xs={12}>
                <br />
                <Panel header="Search Jackpot">
                    <form onSubmit={(e)=>{ callPopular(optionPopularWithDates); e.preventDefault(); }} className="inline-block width100">
                        <Row xl={12} md={12} sm={12} xs={12}>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin10">
                                <label htmlFor="integeronly" className="font-bold block mb-2">Select Jackpot</label>
                                <Dropdown optionLabel="name" value={selectedJackpot} options={jackpots} onChange={(e) => { setSelectedJackpot(e.value)  }} placeholder="Select a Jackpot"/>
                            </Col>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin10">
                                <label htmlFor="integeronly" className="font-bold block mb-2">No. of Data</label>
                                <InputNumber inputId="integeronly" value={limit} onChange={(e) => { setLimit(e.value as number) }} />
                            </Col>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin10">
                                <label htmlFor="integeronly" className="font-bold block mb-2">Skip Data</label>
                                <InputNumber inputId="integeronly" value={skip} onChange={(e) => { setSkip(e.value as number) }} />
                            </Col>
                            <Col xl={3} md={6} sm={12} xs={12} className="top-margin20">
                                <Button label="Search" icon="pi pi-search" className="button-search" />
                            </Col>
                        </Row>
                    </form>
                </Panel>
            </Col>
            <h2 className='width100-background-grey'>Popular {`${optionPopularWithDates.name}`} Golden Numbers ({popularNumber.length})</h2>
            <div className="card">
                <DataTable value={popularNumber} className="sm-4" tableStyle={{ minWidth: '10rem' }}>
                    <Column field="golden" header="Golden" body={addNumberTemplate}></Column>
                    <Column field="count" header="Count"></Column>
                </DataTable>
            </div>
        </>
    )
}

export default PopularGoldenNumbersPage;