import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Api from '../../externals/api';

function MostRecentNumber(props: any) {
    const [ recentNumber, setRecentNumber] = useState<any>([]);

    const callPopular = async (options: any) => {
        const result = await Api.recentNumbers(options);
        if (result.data.data) {
            setRecentNumber(result.data.data);
        }
    }

    useEffect(() => {
        callPopular(props.options);
    }, []);

    return (
        <>
            <h2 className='width100-background-green'>Recent {`${props.options.name}`} Numbers</h2>
            <div className="card">
                <DataTable value={recentNumber} className="sm-4" tableStyle={{ minWidth: '10rem' }}>
                    <Column field="number" header="Number"></Column>
                </DataTable>
                <span className="research-more-box"><a href="" className="bold-a-href">Research More ...</a></span>
            </div>
        </>
    )
}

export default MostRecentNumber;