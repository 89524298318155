import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Api from '../../externals/api';
import AddNumber from './addNumber';

function Numbers(props: any) {

    const colorSet = (num: number, i: number) => {
        if (i === 5) return <span className='list-numberG'>{num}</span>
        if (i === 6) return <span className='list-numberM'>{num}</span>
        return <span className='list-numberA'><AddNumber number={num} name="Powerball" /></span>
    }
    return (
        <>
                { props.numbers.length > 0 && props.numbers.map((item: any, i: number) => {
                    return (
                        <span key={i}>{colorSet(item.number, i)} </span>
                    )
                })}
        </>
    )
}

export default Numbers;