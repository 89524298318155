import React, { useState, useEffect } from 'react';
import { CookieFunctions } from '../../internals/functions';

function AddNumber(props: any) {

    const add = async (num: number, name: any) => {
        // let gotNumber: any = CookieFunctions.get(name);
        // if (gotNumber !== 'undefined') gotNumber = gotNumber.split(',');
        // if (gotNumber && gotNumber.length > 0) {
        //     gotNumber.push(num);
        // } else {
        //     gotNumber = [num];
        // }
        // gotNumber = gotNumber.join(',');
        // await CookieFunctions.set(name, gotNumber, 1);
    }
    return (
        <span onClick={() => { add(props.number, props.name) }}>{props.number}</span>
    )
}

export default AddNumber;