import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Api from '../../externals/api';

function PopularGolden(props: any) {
    const [ popularGolden, setPopularGolden] = useState<any>([]);

    const callPopular = async (options: any) => {
        const result: any = await Api.popularGolden(options);
        if ((result.data.data).length > 0) {
            let results: any[] = result.data.data;
            setPopularGolden(results);
        }
    }

    useEffect(() => {
        callPopular(props.options);
    }, []);

    return (
        <>
            <h2 className='width100-background-red'>Popular {`${props.options.name}`} Golden Numbers</h2>
            <div className="card">
                <DataTable value={popularGolden} className="sm-4" tableStyle={{ minWidth: '10rem' }}>
                    <Column field="golden" header="Golden"></Column>
                    <Column field="count" header="Count"></Column>
                </DataTable>
                <span className="research-more-box"><a href="" className="bold-a-href">Research More ...</a></span>
            </div>
        </>
    )
}

export default PopularGolden;