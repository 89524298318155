import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Api from '../../externals/api';

function SelectedNumbers(props: any) {
    // props.numbers = '1,2,3,4,5,6,7,8,9,10';
    const [splitedNums, setSplitedNums] = useState<Array<number>>([]);

    useEffect(() => {
        console.log('selected numbers: ', props.numbers);
        if (props.numbers && props.numbers.length > 0) {
            let allSplitted = (props.numbers) as Array<number>;
            setSplitedNums(allSplitted);
        }
    },[props.numbers]);

    const colorSet = (num: number) => {
        return <span className='list-numberA'>{num}</span>
    }
    return (
        <div className="numbers-inline">
                { splitedNums.length > 0 && splitedNums.map((item: any, i: number) => {
                    return (
                        <span key={i}>{colorSet(item)} </span>
                    )
                })}
        </div>
    )
}

export default SelectedNumbers;