import { getCookie, setCookie, removeCookie } from 'typescript-cookie';

const cookiesdata = {
    'Powerball': {
        numbers: [],
        goldens: [],
        multipliers: []
    },
    'Mega Millions': {
        numbers: [],
        goldens: [],
        multipliers: []
    }
}

const cookieName = 'collected_number' as string;

export const CookieFunctions = {
    set: async function (name: string, type: string, value: any, expires: number) {
        let getCookie = await this.get();
        let setData: any = getCookie ? JSON.parse(getCookie) : cookiesdata;
        console.log('setData', setData);
        if ((setData[name][type] as any).includes(value) === false) (setData[name][type]).push(value);
        // console.log(setData[name]['numbers'])
        // let thenumber = (setData[name]['numbers']);
        // setData[name]['numbers'] = thenumber.push(value);
        await setCookie(cookieName, JSON.stringify(setData as any) , { expires: expires, path: '' });
    },

    get: async function () {
         let result = await getCookie(cookieName);
         return result;
    },

    delete: async function (name: any) {
        await removeCookie(name);
    },

    addNumber: async function (name: any, type: string, value: number) {
        let presentData = await this.get();
        if (presentData) {
            console.log('addNumber', presentData);
        }
    }
}

export const HeaderChange = (title: string, description: string) => {
    document.title = title;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    }
}