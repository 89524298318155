import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { MenuItem } from 'primereact/menuitem';

export default function Navi() {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [url, setUrl] = useState<string>('./');

    const items: MenuItem[] = [
        {label: 'Home', url: './', icon: 'pi pi-fw pi-home'},
        {label: 'Popular Numbers', url: './popularnumbers', icon: 'pi pi-fw pi-list'},
        {label: 'Popular Golden Numbers', url: './populargoldennumbers', icon: 'pi pi-fw pi-bitcoin'},
        // {label: 'Popular Multiplier Numbers', url: './popularmultipliernumbers', icon: 'pi pi-fw pi-times'},
        {label: 'Recent Jackpot Winner', url: './recentjackpotwinner', icon: 'pi pi-fw pi-file'},
        {label: 'Collected Numbers', url: './collectnumbers', icon: 'pi pi-fw pi-box'}
    ];

    const getUrl = () => {
        const currentUrl = '.'+(window.location.href).replace(/^.*\/\/[^\/]+/, '');
        setUrl(currentUrl);
        if (currentUrl === './') setActiveIndex(0);
        if (currentUrl === './popularnumbers') setActiveIndex(1);
        if (currentUrl === './populargoldennumbers') setActiveIndex(2);
        if (currentUrl === './popularmultipliernumbers') setActiveIndex(3);
        if (currentUrl === './recentjackpotwinner') setActiveIndex(4);
        if (currentUrl === './collectnumbers') setActiveIndex(5);
    }

    useEffect(() => {
        getUrl();
    },[])

    return (
        <div className="card">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
    )
}