import axios from 'axios';
import GLOBAL from '../config/const';

const headers = {
    "Content-type": "application/json",
    "Accept": "application/json"
} as object;

const axiosClient: any = axios.create({
    baseURL: GLOBAL.api,
    headers: headers
} as object);

axiosClient.interceptors.response.use(
    function (response: any) {
    //  console.log(response,"respo")
      return response;
      
    }, 
    function (error: any) {
      let res = error.response;
      if (res.status === 401) {
        window.location.href = "https://example.com/login";
      }
      console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
);

const API = {

    test: function () {
        return "hello world";
    },

    popularJackpot: async function (options: object) {
        const response: any = await axiosClient.post('/data/popularnumber', options);
        return response;
    },

    popularGolden: async function (options: object) {
        const response: any = await axiosClient.post('/data/populargolden', options);
        return response;
    },

    popularJackpotWithDates: async function (options: object) {
        const response: any = await axiosClient.post('/data/popularnumberwithdates', options);
        return response;
    },

    recentNumbers: async function (options: object) {
        const response: any = await axiosClient.post('/data/mostrecentnumbers', options);
        return response;
    },

    jackpotList: async function (options: object) {
        const response: any = await axiosClient.post('/data/groupbydates', options);
        return response;
    }
};

export default API;