import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ListBox } from 'primereact/listbox';
import { Panel } from 'primereact/panel';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button'
import Api from '../../externals/api';
import SelectedNumbers from '../support/selectedNumbers';
import { CookieFunctions, HeaderChange } from '../../internals/functions';

function CollectNumberPage() {

    const [ getSavedNumbers, setGetSavedNumbers ] = useState<any>()

    useEffect(() => {
        HeaderChange('Your selected jackpot numbers - Jackpots Tool', 'Your list of Jackpot numbers from research, and selection. Good luck!');
        Promise.all([CookieFunctions.get()]).then((results) => {
            if (results[0]) setGetSavedNumbers(JSON.parse(results[0] as any));
            console.log('results', JSON.parse(results[0] as any));
        }).catch((error) => {
            console.error('Error getting', error);
        });
        console.log('getSavedNumbers', getSavedNumbers);
    }, []);

    return (
        <>
            {getSavedNumbers ? <>

                {getSavedNumbers['Powerball'] && getSavedNumbers['Powerball']['numbers'].length > 0 ? <div className="card">
                    <>
                        <h2 className='width100-background-grey'>Your Selected Powerball Numbers</h2>
                        <SelectedNumbers numbers={getSavedNumbers['Powerball']['numbers']} />
                    </>
                </div> : '' }

                {getSavedNumbers['Powerball'] && getSavedNumbers['Powerball']['goldens'].length > 0? <div className="card">
                    <>
                        <h2 className='width100-background-grey'>Your Selected Powerball Golden Numbers</h2>
                        <SelectedNumbers numbers={getSavedNumbers['Powerball']['goldens']} />
                    </>
                </div> : '' }

                {getSavedNumbers['Mega Millions'] && getSavedNumbers['Mega Millions']['numbers'].length > 0 ? <div className="card">
                    <>
                        <h2 className='width100-background-grey'>Your Selected Mega Millions Numbers</h2>
                        <SelectedNumbers numbers={getSavedNumbers['Mega Millions']['numbers']} />
                    </>
                </div> : '' }

                {getSavedNumbers['Mega Millions'] && getSavedNumbers['Mega Millions']['goldens'].length > 0 ? <div className="card">
                    <>
                        <h2 className='width100-background-grey'>Your Selected Mega Millions Golden Numbers</h2>
                        <SelectedNumbers numbers={getSavedNumbers['Mega Millions']['goldens']} />
                    </>
                </div> : '' }
            </> : <><br /><center> No number selected yet! </center></> }
        </>
    )
}

export default CollectNumberPage;